<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="herDenmark"
    locale-section="pages.herDenmark"
    field-section="herDe"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "HerDenmarkDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        locality
        fofmParish
        parishCode
      `,
      inputAttributes: {
        locality: { type: "number", required: true },
        parishCode: { required: true }
      }
    };
  }
};
</script>
